import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { type TeamProps } from "@/types";

const OTEam = () => {
  const data = useStaticQuery(graphql`
    query {
      allTeamJson {
        edges {
          node {
            id
            name
            position
            phone
            email
            image
            slug
            bio
          }
        }
      }
    }
  `);

  const team = data.allTeamJson.edges.map(
    (edge: { node: TeamProps }) => edge.node,
  );

  return (
    <div className="container">
      <div
        data-name="Our Team"
        className="rts-title-area center-custom text-center"
      >
        <p className="pre-title more-ab-sect-title more-ab-sect-title-orange">
          About Us
        </p>
        <h2 className="title s-t-home">Our Team</h2>
      </div>
      <div className="row g-5 mt--20 mt_md--30 mt_sm--0 justify-content-center">
        {team.map(({ id, name, position, image, slug }: TeamProps) => (
          <div
            key={`our-team-${String(id)}`}
            className="col-lg-4 col-md-6 col-sm-12 col-12"
          >
            <div className="team-inner-two inner">
              <a href={`/about/team-details/${slug}`} className="thumbnail">
                <img src={image} alt="Team Member" />
              </a>
              <div className="acquaintance-area">
                <div className="header">
                  <h5 className="title">{name}</h5>
                  <span>{position}</span>
                </div>
                <div className="acquaintance-social">
                  <a
                    href="https://www.facebook.com/MekcoGroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://twitter.com/mekcogroup?lang=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/mekcogroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/mekco-group-inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OTEam;
