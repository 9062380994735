import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
const AboutUs = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  return (
    <div className="bg-dark-about">
      <div className="container">
        <div className="ab-wrapper">
          <div className="ab-content-1">
            <h1>Aircraft cabin & connectivity innovation</h1>
            <p>
              The current MEKCO Group repair facility has roots going back to
              AVICOM International, the pioneers of in-flight entertainment.
              Over the years, AVICOM International changed names several times
              and in the process, grew from two technicians in a van, to a
              20,000 square foot FAA repair facility with over 100 employees.
              What remained the same over the years was the dedication to
              provide excellent service, integrity to provide the best value to
              the customer, and the commitment to complete customer
              satisfaction.
            </p>
            <p>
              MEKCO Group continues that same dedication from the ownership to
              its employees. We bring over 30 years of experience with In-Flight
              Entertainment equipment repairs and service. Over the years
              services and products have expanded to include galley equipment,
              cabin equipment, new product offering such as wireless WiFi
              systems, connectivity, digital media and audio plug & play
              components, making MEKCO Group the choice for all your in-cabin
              needs. We have recently added Line Maintenance capabilities for
              your aircraft needs from our MEKCO Aircraft Maintenance division.
              Services include A Checks, Line Checks, Hanger Services, and
              Flight Mechanics.
            </p>
            <p>
              You can rely on the quality and professionalism our staff provides
              to each of our valued customers.
            </p>
          </div>
          <div className="swiper mySwiperAbout">
            <h1 className="c-hidden" style={{ visibility: "hidden" }}>
              Aircraft cabin & connectivity innovation
            </h1>
            <Swiper
              onActiveIndexChange={({ realIndex }) => {
                setCurrentSlide(realIndex + 1);
              }}
              style={{
                height: "363px",
                borderRadius: "30px",
              }}
              pagination={{
                el: ".custom-pagination",
                clickable: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              slidesPerView={1}
              spaceBetween={0}
              loop={true}
              autoplay={{
                delay: 5500,
              }}
              modules={[Pagination, Autoplay, Navigation]}
            >
              <SwiperSlide>
                <img
                  src="/images/breadcrumb/bc01.png"
                  className="w-100"
                  alt="Image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="/images/breadcrumb/bc01.png"
                  className="w-100"
                  alt="Image"
                />
              </SwiperSlide>
            </Swiper>
            <div
              data-slide={`${currentSlide} / `}
              className="swiper-button-next"
            ></div>
            {/* //data-total array length */}
            <div data-total={`${2}`} className="swiper-button-prev"></div>
          </div>
        </div>
        <hr style={{ marginBottom: "auto" }} className="separator-ab" />
      </div>
    </div>
  );
};

export default AboutUs;
