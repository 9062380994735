import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import AboutUs from "@/components/About2/AboutUs";
import Award from "@/components/About2/Award";
import Values from "@/components/About2/Values";
import OTEam from "@/components/About2/OTEam";
import Banner from "@/components/About2/Banner";
import Partners from "@/components/Home/Partners";

const AboutP = () => {
  return (
    <Layout>
      <SEO title="About" canonical={`${useLocation().host}`} />
      <Breadcrumb name="About Us" pathName="About Us" />
      <AboutUs />
      <Award />
      <Values />
      <OTEam />
      <Banner />
      <Partners />
    </Layout>
  );
};

export default AboutP;
