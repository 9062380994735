import React from "react";

const Award = () => {
  return (
    <div className="bg-dark-about" style={{ paddingTop: "58px" }}>
      <div className="container award-main ">
        <h4>Awarded TOP shop by the145.com</h4>
        <div className="award-content-wrapper">
          <img src="/images/award/145.png" width={127} height={127} alt="img" />
          <img
            src="/images/award/145-2.svg"
            width={127}
            height={127}
            alt="img"
          />
          <img
            src="/images/award/145-3.svg"
            width={127}
            height={127}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default Award;
