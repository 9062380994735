import React from "react";

const Values = () => {
  return (
    <div className="container p-values-top-bottom">
      <div
        data-name="Our Values"
        className="rts-title-area center-custom text-center"
      >
        <p className="pre-title more-ab-sect-title more-ab-sect-title-orange">
          About Us
        </p>
        <h2 className="title s-t-home">Our Values</h2>
      </div>
      <div className="values-card-wrapper">
        <div className="values-card">
          <div className="values-card-ct">
            <img src="/images/values/card1.png" />
          </div>
          <div className="values-card-padding">
            <h3>Integrity</h3>
            <p>
              We will conduct business in an{" "}
              <span style={{ color: "#C97E0E", fontWeight: "bold" }}>
                {" "}
                honest{" "}
              </span>{" "}
              and{" "}
              <span style={{ color: "#C97E0E", fontWeight: "bold" }}>
                {" "}
                forthright{" "}
              </span>
              manner in all internal and customer-facing situations. This is the
              foundation of our character and the basis by which customers
              choose MEKCO Group.
            </p>
          </div>
        </div>
        <div className="values-card">
          <div className="values-card-ct">
            <img src="/images/values/card2.png" />
          </div>
          <div className="values-card-padding">
            <h3>Loyalty</h3>
            <p>
              By conducting business with integrity, MEKCO Group will create
              lasting bonds with customers and internal team members.
              <span style={{ color: "#C97E0E", fontWeight: "bold" }}>
                {" "}
                Loyalty is the foundation{" "}
              </span>{" "}
              on which lasting relationships are built, and the clear path for
              growth and success now and in the future.
            </p>
          </div>
        </div>

        <div className="values-card">
          <div className="values-card-ct">
            <img src="/images/values/card3.png" />
          </div>
          <div className="values-card-padding">
            <h3>Experience</h3>
            <p>
              MEKCO Group will continually invest in our team members and
              utilize our collective experience in growing the business. We
              place a{" "}
              <span style={{ color: "#C97E0E", fontWeight: "bold" }}>
                priority on the experience{" "}
              </span>
              of our people as well as the customer’s experience with us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
