import React from "react";

const Banner = () => {
  return (
    <div className="banner-ab-wrapper">
      <div className="container mx-auto text-center">
        <p className="mx-auto">
          “These principles will allow us to deliver innovative solutions for
          aircraft cabin and connectivity needs, all while exceding customer
          expectations at every turn.”
        </p>
        <div className="n-banner">
          <span>William F. Utset</span>
          <span className="two">President / CEO</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
